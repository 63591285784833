import * as React from "react";
import PropTypes from 'prop-types';
import {TextField, useRecordContext} from 'react-admin';
import './attributefield.scss'

export const ErrorListField = (props) => {
    let result = []
    let validationErrorList = useRecordContext(props).validationErrorList;
    validationErrorList.forEach(it=>result.push(<li>{it}</li>));
    if(validationErrorList.length>0){
        return <ul class={"failed-result"}>{result}</ul>
    }else{
        return <span class={"success-result"}>nessun errore di validazione</span>;
    }
}

ErrorListField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};