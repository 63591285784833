const TENANT_KEY = 'website';
export const DEFAULT_TENANT_VALUE = '';

export const setTenantData = (tenant) => localStorage.setItem(TENANT_KEY, tenant)
export const getTenantData = () => {
    let returnValue = localStorage.getItem(TENANT_KEY);
    if (returnValue === undefined)
        setTenantData(DEFAULT_TENANT_VALUE)
    return returnValue
}

export function getTenantList() {
    return {
        it: {
            label: "Denworker italiano",
            logo: "https://www.denworker.com/wp-content/themes/golmart-child/images/logo.png"
        },
        en: {
            label: "Denworker english",
            logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/2560px-Google_2015_logo.svg.png"
        }
    }
}