import * as React from "react";
import {FAILED, LoadingIcon, PROGRESS, SUCCESS} from "./LoadingIcon";

export const RestCallResult = props => {
    let message = ''
    let messageClass = ''
    switch (props.status){
        case SUCCESS:
            message = props.message
            messageClass = 'result-feedback success-result'
            break;

        case FAILED:
            message = props.message
            messageClass = 'result-feedback failed-result'
            break;

        case PROGRESS:
            message = props.message
            messageClass = ''
            break;
    }
    if (props.status === FAILED) {
    //     manageError(props.detail)
    //     messageClass = "error"
    } else if (props.status === SUCCESS) {
    //     messageClass = "success"
    }
    return <div>
        <div>
            <LoadingIcon actionStatus={props.status}/>
        </div>
        <div className={messageClass}>{message}</div>
    </div>
}
//
// const manageSuccess = (details)
//
// const manageError = (detail) => {
//     // switch (detail.response.status) {
//     //     case 400:
//     //         return ('La richiesta non è corretta')
//     //     case 401:
//     //         return ('Mancano i permessi per accedere alla risorsa')
//     //     case 404:
//     //         return ('Risorsa non trovata')
//     //     case 502:
//     //         return ('Connessione al sito non disponibile')
//     //     default:
//     //         return ('Errore interno del server')
//     // }
// }