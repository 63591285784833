import * as React from "react";
import {useRecordContext} from "react-admin";
import {ActionButton, primaryStyle} from "../../../utils/buttons/ActionButton";
import {Link} from "@material-ui/icons";

export const GotoButton = (props) => {
    const record = useRecordContext(props);
    const onClick = () => {
        props.action(record);
    }
    return <div>
        <ActionButton icon={<Link/>} label={props.label} action={onClick} style={primaryStyle}/>
    </div>
}