import * as React from "react";
import {Login, LoginForm} from 'react-admin';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getTenantData, getTenantList, setTenantData} from "../dataprovider/tenantService";


const CustomLoginPage = () => {

    const getLogo = (tenantId)=> {

        let tenantList = getTenantList();
        if(tenantList[tenantId]){
            return tenantList[tenantId]['logo']
        }else{
            return ''
        }
    }
    const [tenantId, setTenantId] = React.useState(getTenantData());
    const [logo, setLogo] = React.useState(getLogo(tenantId));

    const handleChange = (event) => {
        let tenant = event.target.value;
        setTenantId(tenant);
        setTenantData(tenant)
        setLogo(getLogo(tenant))
    };

    let logoImage = logo===""?'':<div style={{width: "100%", textAlign: "center"}}><img src={logo} alt={"Denworker"} style={{maxWidth: "220px"}}/></div>;
    const getChildren = () => <div>
        {logoImage}
        {language()}
        <LoginForm></LoginForm>
    </div>;

    const language = () => {
        let menuItems = []
        let tenantList = getTenantList();
        Object.keys(tenantList).forEach(it => menuItems.push(<MenuItem value={it}>{tenantList[it]['label']}</MenuItem>))

        return <div>
            <FormControl style={{"width": "90%", padding: "20px"}}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tenantId}
                    color={'secondary'}
                    onChange={handleChange}
                >
                    {menuItems}
                </Select>
            </FormControl>
        </div>
    }

    return <Login children={getChildren()}></Login>
};

export default CustomLoginPage;
