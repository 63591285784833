import * as React from "react";
import {useState} from "react";
import PropTypes from 'prop-types';
import {ListAlt} from "@material-ui/icons";
import {ActionButton} from "../../../utils/buttons/ActionButton";
import {FAILED, INACTIVE, LoadingIcon, SUCCESS} from "../../../utils/loading/LoadingIcon";
import {useRefresh} from "react-admin";


const GetDeleteListButton = (props) => {
    const [listStatus, setListStatus] = useState()
    const [errorDescription, setErrorDescription] = useState()
    const doRefresh = useRefresh()
    const callback = (status, errorDescription) => {
        setListStatus(status)
        setErrorDescription(errorDescription)
        if (status === SUCCESS) {
            doRefresh()
        }
    };
    return <div key={"get-delete-list-button-wrapper"}>
        <div key={"action-button"}>
            <ActionButton label={props.label} icon={<ListAlt/>} action={() => props.action(callback)}/>
        </div>
        <div key={"loading-icon"}>
            <LoadingIcon actionStatus={listStatus === undefined ? INACTIVE : listStatus}></LoadingIcon>
        </div>
        <div key={"feedback-ok"}
             className={"result-feedback success-result " + (listStatus === SUCCESS ? '' : 'hide-btn')}>
            Download completato
        </div>
        <div key={"feedback-ko"}
             className={"result-feedback failed-result " + (listStatus === FAILED ? '' : 'hide-btn')}>
            {errorDescription}
        </div>
    </div>
}

GetDeleteListButton.propTypes = {
    label: PropTypes.string
};

export default GetDeleteListButton;