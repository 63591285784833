import * as React from "react";
import {useState} from "react";
import {Datagrid, DateField, List, Pagination, TextField} from 'react-admin';
import {Accordion, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import '../common.scss'
import DropUI from "../utils/upload/DropUI";
import {INACTIVE} from "../utils/loading/LoadingIcon";
import {EXCEL_MIME, JPEG_MIME, ZIP_MIME} from "../../config/filetypes";
import {apiVersion} from "../../config/exchange";
import {ImportWizard} from "./import-wizard";
import CustomersFilter from "../utils/filters/customers-filter";
import {getCustomersChainStatus} from "../actions/get";
import {createCustomersChain} from "../actions/post";
import {stopSyncCustomersTask, stopSyncSitemapTask} from "../actions/put";

const uploadImagesEndpoint = `${apiVersion}/images/batch`
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;
const acceptedProductsUpload = [EXCEL_MIME];
const acceptedImagesUpload = [JPEG_MIME];
const acceptedImagesZipUpload = [ZIP_MIME];
export const CustomersList = props => {
    const [actionStatus, setActionStatus] = React.useState(INACTIVE);

    const [expanded, setExpanded] = React.useState('panel3');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [websiteUrl, setWebsiteUrl] = useState('')


    return (
        <div className={"resource-panel"}>
            <Accordion defaultExpanded={true} expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Stato importazione</Typography>
                </AccordionSummary>
                <div>
                    <ImportWizard stopTaskStatus={stopSyncCustomersTask} createChain={(it)=>createCustomersChain(it)} getChainStatus={(it) => getCustomersChainStatus(it)}  downloadFile={false}></ImportWizard>
                </div>
            </Accordion>
            <Accordion defaultExpanded={true} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Carica file Clienti</Typography>
                </AccordionSummary>
                <div>
                    <DropUI accepted={acceptedProductsUpload} single={true}
                            endpoint={`${apiVersion + '/customer/upload'}`}></DropUI>
                </div>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>Report</Typography>
                </AccordionSummary>
                <div className={"resource-list-wrapper"}>

                    <List bulkActionButtons={false} pagination={<PostPagination/>} {...props}
                          filters={<CustomersFilter/>}
                          title={"Lista dei clienti caricati sul sito"}>
                        <Datagrid>
                            <TextField source="id" sortable={true}/>
                            <TextField source="ref" sortable={false}/>
                            <TextField source="status"/>
                            <DateField source="updateTime" showTime={true} sortable={true}/>
                        </Datagrid>
                    </List>
                </div>
            </Accordion>
        </div>
    );
};