import {Filter, ReferenceInput, SelectInput, TextInput} from "react-admin";
import * as React from "react";

export default function customersfilter(props){
    return (
        <Filter {...props}>
            <TextInput label="E-mail o codice cliente" source="ref" alwaysOn/>
            <SelectInput source="status" alwaysOn choices={[
                {id:'Created', name: 'Created'},
                {id:'Updated', name: 'Updated'},
                {id:'Failed', name: 'Failed'},
                {id:'Read', name: 'Read'}
            ]
            }/>
        </Filter>
    );
}