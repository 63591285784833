import {CircularProgress} from "@material-ui/core";
import * as React from "react";
import PropTypes from "prop-types";

export const INACTIVE = 0
export const PROGRESS = 1
export const SUCCESS = 2
export const FAILED = -1

export const LoadingIcon = (props) => {
    let description = '';
    if (props.description !== undefined) {
        description = props.description
    }
    return <div key={'loading-icon'} className={"result-feedback " + (props.actionStatus === PROGRESS ? '' : 'hide-btn')}>
        <CircularProgress/>
        <div key={'description-wrapper'}>
            {description}
        </div>
    </div>
}

LoadingIcon.propTypes = {
    description: PropTypes.string,
    actionStatus: PropTypes.number.isRequired
};