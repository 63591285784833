import React, {useCallback, useState} from 'react'
import {useDropzone} from "react-dropzone";
import './DropUI.scss'
import '../../common.scss'
import {Button} from "@material-ui/core";
import {CloudUpload, Undo} from "@material-ui/icons";
import FileList from "./filelist";
import {ActionButton, secondaryStyle} from "../buttons/ActionButton";
import {INACTIVE} from "../loading/LoadingIcon";
import {uploadImage} from "../../actions/post";

const _ = require('lodash');

export default function DropUI(props) {
    const [fileList, setFileList] = useState([])

    const onDrop = useCallback(acceptedFiles => {
        let files = []
        const getFileData = (status, data, message, endpoint) => {
            return {'status': status, 'data': data, 'message': message, 'endpoint': endpoint};
        }
        if (props.single) {
            files.push(getFileData(INACTIVE, _.last(acceptedFiles), '', props.endpoint))
        } else {
            acceptedFiles.map((data) => {
                files.push(getFileData(INACTIVE, data, '', props.endpoint))
            });
        }
        setFileList(files)
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    let uploadAction = (e) => {
        fileList.map((file) => {
            let uploadStatusCallback = (e, m) => {
                let list = []
                fileList.map((currentFile) => {
                    if (currentFile === file) {
                        file.status = e
                        file.message = m
                        list.push(file)
                    } else {
                        list.push(currentFile)
                    }
                })
                setFileList(list)
            };
            uploadImage(file.data, file.endpoint, uploadStatusCallback)
        });
    };
    return <div className={'drop-ui-wrapper'}>
        <div {...getRootProps()} className={"dropzone-wrapper "}>
            <div className={"upload-excel-wrapper"}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Trascina i file qui ...</p> :
                        <p>Trascina qui i file o clicca per selezionarli dal tuo HD</p>
                }
            </div>
        </div>
        <div className={"dropzone-action-toolbar-wrapper"}>
            {getToolbar(fileList, uploadAction, setFileList)}
        </div>
        <div className={'dropzone-file-list'}>
            <FileList accepted={props.accepted} files={fileList} successEndpoint={props.successEndpoint}></FileList>
        </div>
        <div className={"dropzone-action-toolbar-wrapper"}>
            {getToolbar(fileList, uploadAction, setFileList)}
        </div>
    </div>
}

const getToolbar = (fileList, uploadAction, setFileList) => {
    let toolbar = ''
    if (fileList.length > 0) {
        toolbar = <div className={'dropzone-action-filelist-group'}>
            <div className={'dropzone-action-filelist ' + (fileList.length > 0 ? '' : 'hide-btn')}>
                <Button
                    endIcon={<CloudUpload/>}
                    variant="contained"
                    color="primary"
                    onClick={uploadAction}>Carica</Button>
            </div>
            <div className={'dropzone-action-filelist ' + (fileList.length > 0 ? '' : 'hide-btn')}>
                <ActionButton
                    label={'Annulla'}
                    icon={<Undo/>}
                    variant="contained"
                    color="secondary"
                    style={secondaryStyle}
                    action={(e) => setFileList([])}
                />
            </div>
        </div>;
    }
    return toolbar;
}