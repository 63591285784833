export const apiUrl = getUrl()

function getUrl(){
    const data = {
        // "localhost": "http://localhost:9443",
        "localhost": "http://localhost:8080",
        "staging.admin.denworker.com": "https://staging.exchange.denworker.com:9443",
        "admin.denworker.com": "https://exchange.denworker.com:8443"
    }
    return data[window.location.hostname]
}

export const apiVersion = `/api/v1`;