import {AppBar} from "react-admin";
import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import {DEFAULT_TENANT_VALUE, getTenantData} from "../dataprovider/tenantService";

const tenantData = {
    "it": "Sito web italiano",
    "en": "Sito web inglese",
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    middleWrapper: {
        minWidth: '75%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row-reverse'
    }
}));

const ExchangeToolbar = (props) => {
    const classes = useStyles();
    const [tenantId, setTenantId] = React.useState(getTenantData());


    let tenantDatum = tenantData[tenantId];
    if(undefined===tenantDatum)
        tenantDatum = DEFAULT_TENANT_VALUE
    return (
        <AppBar
            sx={{
                "& .RaAppBar-title": {
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },
            }}
            {...props}
        >
            <div className={classes.middleWrapper}>
                <Typography>{tenantDatum.toUpperCase()}</Typography>
            </div>
        </AppBar>
    );
}

export default ExchangeToolbar;