import {apiUrl} from "../../../config/exchange";
import axios from "axios";

const headers = {
    headers: {
        'Authorization': localStorage.getItem('token')
    }
}

function checkAuthToken() {
    if (!headers.headers.Authorization) {
        throw new DOMException('Authorization token not set')
    }
}

export const _get = (endpoint) => {
    checkAuthToken();
    endpoint = addTenant(endpoint)
    return axios.get(`${apiUrl}${endpoint}`, headers)
}

export const getFile = (endpoint) => {
    checkAuthToken();
    let config = {responseType: 'blob'};
    config['headers'] = headers.headers;
    endpoint = addTenant(endpoint)
    return axios.get(`${apiUrl}${endpoint}`, config);
}

export const post = (endpoint, body) => {
    checkAuthToken();
    endpoint = addTenant(endpoint)
    return axios.post(`${apiUrl}${endpoint}`, body, headers)
}

export const put = (endpoint, body) => {
    checkAuthToken();
    endpoint = addTenant(endpoint)
    return axios.put(`${apiUrl}${endpoint}`, body, headers)
}

export const postFile = (endpoint, body) => {
    checkAuthToken();
    headers['Content-Type'] = 'multipart/form-data'
    endpoint = addTenant(endpoint)
    return axios.post(`${apiUrl}${endpoint}`, body, headers)
}

export const _delete = (endpoint) => {
    checkAuthToken();
    endpoint = addTenant(endpoint)
    return axios.delete(`${apiUrl}${endpoint}`, headers)
}

const addTenant = (endpoint) => {
    let tenant = localStorage.getItem('website')
    return `${endpoint}?tenant=${tenant}`
}