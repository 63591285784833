import * as React from "react";
import {useEffect, useState} from "react";
import {ActionButton} from "../utils/buttons/ActionButton";
import {downloadProductsFile} from "../actions/get";

export const CheckFileIsAvailable = (props) => {
    const downloadAction = () => downloadProductsFile((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'products.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        if (response.code === 200) {
            setDownloadResult(downloadActionButton)
        } else {
            setDownloadResult(response.message)
        }
    })
    const checkFile = () => {
        downloadProductsFile((response) => {
            if (response.type === 'application/octet-stream') {
                setDownloadResult(downloadActionButton)
            } else {
                setDownloadResult('')
            }
        })
    }
    let downloadActionButton = <ActionButton label={'Scarica'} action={downloadAction}></ActionButton>
    const [downloadResult, setDownloadResult] = useState('')
    useEffect(() => {
        const interval = setInterval(() => {
            checkFile()
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return <div>
        {downloadResult}
    </div>
}