import {_delete, post, postFile, put} from "../utils/rest/RestClient";
import {FAILED, PROGRESS, SUCCESS} from "../utils/loading/LoadingIcon";
import {apiVersion} from "../../config/exchange";

export const createTaskStatus = (body, callback) => {
    if(body.status===''){
        return null;
    }
    callback(PROGRESS, '')
    post(`${apiVersion}/schedule/`, body)
        .then(function (response) {
            console.log(response.data)
            callback(SUCCESS, response.data)
        })
        .catch(function (error) {
            let message = error.response.data.message;
            if (message === '') {
                message = error.response.data.error
            }
            callback(FAILED, message)
        })
}

export const uploadImage = (data, endpoint, callback) => {
    let formData = new FormData()
    formData.append("file", data)
    callback(PROGRESS)
    postFile(endpoint, formData)
        .then(function (response) {
            let message = response.data.message;
            callback(SUCCESS, message)
        })
        .catch(function (error) {
            let message = error.response.data.message;
            callback(FAILED, message)
        })
}

export const uploadProducts = (body, callback) => {
    callback(PROGRESS, '')
    post(`${apiVersion}/website/upload/`, body)
        .then(function (response) {
            callback(SUCCESS, response.message)
        })
        .catch(function (error) {
            callback(FAILED, error.response.data.message)
        })
}

export const importFile = (body, endpoint, callback) => {
    callback(PROGRESS, '')
    post(endpoint, body)
        .then(function (response) {
            callback(SUCCESS, '')
        })
        .catch(function (error) {
            callback(FAILED, error.response.data.message)
        })
}

export const createProductsChain = (callback) => {
    post(`${apiVersion}/job/sync-products/start`, {})
        .then(function (response) {
            callback(SUCCESS, '')
        })
        .catch(function (error) {
            callback(FAILED, error.response.data.message)
        })
}

export const createSitemapChain = (callback) => {
    post(`${apiVersion}/job/sync-sitemap/start`, {})
        .then(function (response) {
            callback(SUCCESS, '')
        })
        .catch(function (error) {
            callback(FAILED, error.response.data.message)
        })
}

export const createCustomersChain = (callback) => {
    post(`${apiVersion}/job/sync-customers/start`, {})
        .then(function (response) {
            callback(SUCCESS, '')
        })
        .catch(function (error) {
            callback(FAILED, error.response.data.message)
        })
}

export const updateChannableFileAction = (callback) => {
    const endpoint = `${apiVersion}/channable/csv`;
    callback(PROGRESS, '')
    post(`${endpoint}`, callback)
        .then(function (response) {
            callback(SUCCESS, response.data.status, response.data.message)
        })
        .catch(function (error) {
            callback(FAILED, error.response.status, error.response.data.message)
        });
}

export const restoreStaging = (callback) => {
    post(`${apiVersion}/admin/restore-staging`, {})
        .then(function (response) {
            callback(SUCCESS, '')
        })
        .catch(function (error) {
            callback(FAILED, error.response.data.message)
        })
}