import * as React from "react";
import {useState} from "react";
import {Datagrid, DateField, List, TextField, useRefresh} from "react-admin";
import {Accordion, AccordionSummary, Typography} from "@material-ui/core";
import {Delete, ExpandMore} from "@material-ui/icons";
import '../common.scss'
import DenworkerComFilter from "../utils/filters/denworker-com-filter"
import {DeleteRemoteList} from "./denworker/deleteRemoteList";
import {ActionButton, secondaryStyle} from "../utils/buttons/ActionButton";
import {FAILED, INACTIVE, LoadingIcon, SUCCESS} from "../utils/loading/LoadingIcon";
import {uploadProducts} from "../actions/post";
import {deleteAllDenworkerProducts} from "../actions/delete";
import UpdateButton from "./denworker/buttons/UpdateButton";
import {GotoButton} from "./denworker/buttons/GotoButton";
import {getSiteData} from "../actions/get";
import ScheduleDatetimeField from "../utils/customfields/ScheduleDatetimeField/ScheduleDatetimeField";

export const DenworkerUploadList = props => {
    const [skuList, setSkuList] = useState([])
    const [expanded, setExpanded] = React.useState('panel3');
    const [actionStatus, setActionStatus] = React.useState(INACTIVE);
    const [importStatus, setImportStatus] = useState(INACTIVE)
    const [errorDetail, setErrorDetail] = useState('')
    const [websiteUrl, setWebsiteUrl] = useState('')

    const refreshReport = useRefresh();
    const deleteCallback = (status) => {
        setActionStatus(status)
        refreshReport()
    }
    const deleteAllAction = () => deleteAllDenworkerProducts(deleteCallback)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const uploadAction = (id, callback) => uploadProducts([id], callback)

    if (websiteUrl === '') {
        getSiteData(setWebsiteUrl)
    }

    return (<div className={"resource-panel"}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Cancella su Denworker.com</Typography>
                </AccordionSummary>
                <div>
                    <DeleteRemoteList/>
                </div>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>Report</Typography>
                </AccordionSummary>
                <div className={"resource-list-wrapper"}>

                    <div className={"import-excel-file-button-wrapper"}>
                        <div className={"resource-list-wrapper"}>
                            <Typography>Importa su Denworker.com</Typography>
                            <ScheduleDatetimeField name={'Upload denworker.com products'}
                                                   params={JSON.stringify(skuList)}/>
                            <LoadingIcon actionStatus={importStatus}/>
                            <div
                                className={"result-feedback success-result " + (importStatus === SUCCESS ? '' : 'hide-btn')}>
                                I dati del file sono stati importati con successo
                            </div>
                            <div
                                className={"result-feedback failed-result " + (importStatus === FAILED ? '' : 'hide-btn')}>
                                {errorDetail}
                            </div>
                        </div>
                    </div>

                    <ActionButton
                        style={secondaryStyle}
                        label={'AZZERA IL REPORT'}
                        icon={<Delete></Delete>}
                        variant="contained"
                        color="primary"
                        action={deleteAllAction}
                    />

                    <LoadingIcon actionStatus={actionStatus}/>

                    <List bulkActionButtons={false} exporter={false}  {...props} filters={<DenworkerComFilter/>}
                          title={"Lista dei prodotti caricati sul sito"}>
                        <Datagrid>
                            <TextField label="id" source="id"/>
                            <TextField source="ref" sortable={false}/>
                            <TextField source="name" sortable={false}/>
                            <TextField source="status"/>
                            <DateField source="updateTime" showTime={true} sortable={false}/>
                            <UpdateButton props={props} action={uploadAction}></UpdateButton>
                            <div className={(websiteUrl !== '' ? '' : 'hide-btn')}>
                                <GotoButton label={'APRI'}
                                            action={(e) => window.open(websiteUrl + '?s=' + e.ref, '_blank')}
                                />
                            </div>
                        </Datagrid>
                    </List>

                </div>
            </Accordion>
        </div>);
};