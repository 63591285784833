import * as React from "react";
import {DateField, NumberField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin';
import {ErrorListField} from "../../utils/customfields/ErrorListField";
import ImageField from "ra-ui-materialui/lib/field/ImageField";
import UrlField from "ra-ui-materialui/lib/field/UrlField";

function ItemTitle({record}) {
    return <span>{record ? `${record.sku} ${record.title}` : ''}</span>
}

export default function ChannableDataDetail(props) {
    return <Show title={"Ouooi"} {...props}>
    {/*return <Show title={<ItemTitle/>} {...props}>*/}
        <TabbedShowLayout>
            <Tab label="Tutti i canali">
                <TextField  label="id" source="sku"/>
                <ErrorListField addLabel={true} source="validationErrorList"/>
                <DateField addLabel={true} showTime={true} source="updateTime"/>
                <TextField label="Title" source="title" sortable={false}/>
                <TextField label="Description" source="description" sortable={false}/>
                <UrlField label="Link" source="link" target={"_blank"} sortable={false}/>
                <NumberField label="Price" locales="it-IT" options={{style: 'currency', currency: 'EUR'}} addLabel={true} source="price" sortable={false}/>
                <ImageField label="Image Link" source="imageLink1" sortable={false}/>
            </Tab>
            <Tab label="AMAZON">
                <TextField addLabel={true} label={"Codice EAN"} source="ean"/>
                <TextField addLabel={true} label={"Categoria Amazon"} source="amazonCategory"/>
                <TextField addLabel={true} label={"Tempi di consegna al corriere"} source="amazonFullfillmentLatency"/> Giorni
                <TextField addLabel={true} label={"Template spese di spedizione"} source="amazonShippingTemplate"/>
                <TextField addLabel={true} label={"Condizione"} source="amazonCondition"/>
                <TextField addLabel={true} label={"Template spese di spedizione"} source="amazonShippingTemplate"/>
                <TextField addLabel={true} label={"Paese d'origine"} source="amazonCountryOfOrigin"/>
                <TextField addLabel={true} label={"Quantità"} source="amazonStock"/>
                <TextField addLabel={true} label={"Brand"} source="amazonBrand"/>
                <TextField addLabel={true} label={"Produttore"} source="amazonProducer"/>
                <TextField addLabel={true} label={"Merci pericolose"} source="amazonDangerousGoods"/>
                <TextField addLabel={true} label={"Pittogrammi di avvertenza GHS"} source="amazonGhsIcons"/>
                <TextField addLabel={true} label={"E' una batteria"} source="amazonIsBattery"/>
                <TextField addLabel={true} label={"Unità di misura del volume"} source="amazonItemVolumeUnitOfMeasure"/>
                <TextField addLabel={true} label={"Unità di misura del peso"} source="amazonItemWeightUnitOfMeasure"/>
                <TextField addLabel={true} label={"Url scheda di sicurezza"} source="amazonSecuritySheet"/>
                <TextField addLabel={true} label={"Numero UN"} source="amazonUnNumber"/>
                <TextField addLabel={true} label={"Unità"} source="amazonUnitNumber"/>
                <TextField addLabel={true} label={"Volume"} source="amazonVolume"/>
                <TextField addLabel={true} label={"Peso"} source="amazonWeight"/>
                <table>
                    <tbody>
                        <tr>
                            <td><ImageField label="Immagine 1" source="imageLink1" sortable={false}/></td>
                            <td><ImageField label="Immagine 2" source="imageLink2" sortable={false}/></td>
                            <td><ImageField label="Immagine 3" source="imageLink3" sortable={false}/></td>
                            <td><ImageField label="Immagine 4" source="imageLink4" sortable={false}/></td>
                        </tr>
                    </tbody>
                </table>
            </Tab>



            <Tab label="MANOMANO">
                <TextField addLabel={true} label={"Codice EAN"} source="ean"/>
                <TextField addLabel={true} label={"Prezzo IVA inclusa"} source="manomanoPriceVatIncl"/>
                <TextField addLabel={true} label={"Spese di spedizione IVA inclusa"} source="manomanoShippingPriceVatIncl"/> Giorni
                <TextField addLabel={true} label={"Quantità"} source="manomanoQuantity"/>
                <TextField addLabel={true} label={"Categoria"} source="manomanoCategory"/>
                <TextField addLabel={true} label={"Prezzo Retail IVA inclusa"} source="manomanoRetailPriceVatIncl"/>
                <TextField addLabel={true} label={"% IVA prezzo Prodotto"} source="manomanoProductVatRate"/>
                <TextField addLabel={true} label={"% IVA spedizione"} source="manomanoShippingVatRate"/>
                <TextField addLabel={true} label={"PDF"} source="manomano_manufacturerPdf"/>
                <TextField addLabel={true} label={"Parent Codice"} source="manomanoParentSku"/>
                <TextField addLabel={true} label={"Nome Prodotto"} source="manomanoProductTitle"/>
                <TextField addLabel={true} label={"Codice Prodotti correlati"} source="manomanoCrossSellSku"/>
                <TextField addLabel={true} label={"Durata garanzia"} source="manomanoManufacturerWarrantyTime"/>
                <TextField addLabel={true} label={"Spedizioniere"} source="manomanoCarrier"/>
                <TextField addLabel={true} label={"Giorni per la consegna"} source="manomanoShippingDaysTime"/>
                <TextField addLabel={true} label={"Usa matrice spedizione"} source="manomanoUseGrid"/>
                <TextField addLabel={true} label={"Matrice spedizione Spedizioniere"} source="manomanoShippingTimeCarrierGrid1"/>
                <TextField addLabel={true} label={"Spedito da ManoMano"} source="manomanoIsMmf"/>
                <TextField addLabel={true} label={"Peso netto"} source="manomanoNet"/>
                <TextField addLabel={true} label={"Identification of ICPE danger class"} source="manomanoIcpe"/>
                <TextField addLabel={true} label={"Identification of merchandise with regulated transport"} source="manomanoUn"/>
                <TextField addLabel={true} label={"Safety Data Sheet number"} source="manomanoSecurityFileNumber"/>
                <TextField addLabel={true} label={"The product has a batch number. 1 for yes, 0 for no"} source="manomanoHasBatchNumber"/>
                <TextField addLabel={true} label={"The product has a serial number. 1 for yes, 0 for no"} source="manomanoHasSerialNumber"/>
                <TextField addLabel={true} label={"The product has a use-by date. 1 for yes, 0 for no"} source="manomanoHasDluo"/>
                <TextField addLabel={true} label={"The product is fragile. 1 for yes, 0 for no"} source="manomanoIsFragile"/>
                <TextField addLabel={true} label={"The product requires addtional packaging. 1 for yes, 0 for no"} source="manomanoOverpackaging"/>
                <TextField addLabel={true} label={"Explanation of how the overpackaging should be performed"} source="manomanoIsOverpackaged"/>
                <table>
                    <tbody>
                    <tr>
                        <td><ImageField label="Immagine 1" source="imageLink1" sortable={false}/></td>
                        <td><ImageField label="Immagine 2" source="imageLink2" sortable={false}/></td>
                        <td><ImageField label="Immagine 3" source="imageLink3" sortable={false}/></td>
                        <td><ImageField label="Immagine 4" source="imageLink4" sortable={false}/></td>
                        <td><ImageField label="Immagine 5" source="imageLink5" sortable={false}/></td>
                    </tr>
                    </tbody>
                </table>
            </Tab>

            <Tab label="GOOGLE SHOPPING">
                <TextField addLabel={true} label={"Id"} source="googleShoppingId"/>
                <TextField addLabel={true} label={"Nome"} source="googleShoppingTitle"/>
                <TextField addLabel={true} label={"Brand"} source="googleShoppingBrand"/>
                <TextField addLabel={true} label={"Prezzo"} source="googleShoppingPrice"/>
                <TextField addLabel={true} label={"Disponibilità"} source="googleShoppingAvailability"/>
                <TextField addLabel={true} label={"Condizione"} source="googleShoppingCondition"/>
                <TextField addLabel={true} label={"Categoria"} source="googleShoppingProductCategory"/>
                <TextField addLabel={true} label={"Link"} source="googleShoppingLink"/>
                <table>
                    <tbody>
                    <tr>
                        <td><ImageField label="Immagine 1" source="imageLink1" sortable={false}/></td>
                        <td><ImageField label="Immagine 2" source="imageLink2" sortable={false}/></td>
                        <td><ImageField label="Immagine 3" source="imageLink3" sortable={false}/></td>
                        <td><ImageField label="Immagine 4" source="imageLink4" sortable={false}/></td>
                        <td><ImageField label="Immagine 5" source="imageLink5" sortable={false}/></td>
                    </tr>
                    </tbody>
                </table>
            </Tab>

            <Tab label="INSTAGRAM">
                <TextField addLabel={true} label={"Disponibilità"} source="instagramAvailability"/>
                <TextField addLabel={true} label={"Id"} source="instagramId"/>
                <TextField addLabel={true} label={"Nome"} source="instagramTitle"/>
                <TextField addLabel={true} label={"Brand"} source="instagramBrand"/>
                <TextField addLabel={true} label={"Prezzo"} source="instagramPrice"/>
                <TextField addLabel={true} label={"Condizione"} source="instagramCondition"/>
                <TextField addLabel={true} label={"Categoria"} source="googleShoppingProductCategory"/>
                <TextField addLabel={true} label={"Descrizione"} source="instagramDescription"/>
                            <TextField addLabel={true} label={"Link"} source="googleShoppingLink"/>
                <TextField addLabel={true} label={"EAN"} source="instagramGtin"/>
                <TextField addLabel={true} label={"MPN"} source="instagramMPN"/>
                <table>
                    <tbody>
                    <tr>
                        <td><ImageField label="Immagine 1" source="instagramImageLink" sortable={false}/></td>
                        <td><ImageField label="Immagine 2" source="instagramAdditionalImageLink" sortable={false}/></td>
                    </tr>
                    </tbody>
                </table>
            </Tab>

        </TabbedShowLayout>
    </Show>
}
