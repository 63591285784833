import * as React from "react";
import {Datagrid, List, TextField, useRefresh} from 'react-admin';
import {Accordion, AccordionSummary, Typography} from "@material-ui/core";
import {Cached, ExpandMore, Publish} from "@material-ui/icons";
import ProductFilter from "../utils/filters/productfilter"
import '../common.scss'
import RecordPagination from "../utils/pagination/recordpagination";
import {INACTIVE} from "../utils/loading/LoadingIcon";
import ChannablePublished from "../utils/customfields/ChannablePublished";
import ChannableDataDetail from "./ChannableData/ChannableDataDetail";
import {ActionButton, primaryStyle, secondaryStyle} from "../utils/buttons/ActionButton";
import {refreshChannableCacheAction} from "../actions/delete";
import {RestCallResult} from "../utils/loading/RestCallResult";
import {updateChannableFileAction} from "../actions/post";

export const ChannableData = props => {
    const [actionStatus, setActionStatus] = React.useState(INACTIVE);
    const [actionMessage, setActionMessage] = React.useState('');
    const [actionCode, setActionCode] = React.useState(0);

    const [expanded, setExpanded] = React.useState('panel3');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const refreshReport = useRefresh();

    return (
        <div className={"resource-panel"}>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>Report</Typography>
                </AccordionSummary>
                <div className={"resource-list-wrapper"}>

                    <div style={{display: 'table'}}>
                        <div style={{display: 'table-cell', paddingRight: '20px'}}>
                            <ActionButton
                                style={secondaryStyle}
                                label={'AGGIORNA LA CACHE'}
                                icon={<Cached></Cached>}
                                variant="contained"
                                color="primary"
                                action={() => refreshChannableCacheAction((status, actionCode, actionMessage) => {
                                    setActionMessage(actionMessage)
                                    setActionStatus(status)
                                    setActionCode(actionCode)
                                    refreshReport()
                                })}
                            />
                        </div>
                        <div style={{display: 'table-cell'}}>
                            <ActionButton
                                style={primaryStyle}
                                label={'INVIA IL FILE A CHANNABLE'}
                                icon={<Publish></Publish>}
                                variant="contained"
                                color="primary"
                                action={() => updateChannableFileAction((status, actionCode, actionMessage) => {
                                    setActionMessage(actionMessage)
                                    setActionStatus(status)
                                    setActionCode(actionCode)
                                    refreshReport()
                                })}
                            />
                        </div>
                    </div>

                    <RestCallResult actionCode={actionCode} message={actionMessage} status={actionStatus}/>

                    <List bulkActionButtons={false} exporter={false} filters={<ProductFilter/>} {...props}
                          pagination={<RecordPagination/>}
                          title="Prodotti caricati dal file Excel">
                        <Datagrid expand={<ChannableDataDetail/>}>
                            <TextField label="id" source="sku"/>
                            <TextField label="Title" source="title" sortable={false}/>
                            <TextField label="Status" source="status" sortable={false}/>
                            <ChannablePublished label="Amazon" sortable={false} source={"amazonCategory"}
                                                condition={(it) => it.validationErrorList.length === 0}/>
                        </Datagrid>
                    </List>
                </div>
            </Accordion>
        </div>
    );
};