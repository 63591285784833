import * as React from "react";
import {useState} from "react";
import {Datagrid, DateField, List, TextField} from "react-admin";
import {Accordion, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import '../common.scss'
import {getSiteData, getSitemapChainStatus} from "../actions/get";
import RecordPagination from "../utils/pagination/recordpagination";
import SitemapFilter from "../utils/filters/sitemap-filter";
import {ImportWizard} from "./import-wizard";
import {createSitemapChain} from "../actions/post";
import {stopSyncSitemapTask} from "../actions/put";

export const SitemapEntries = props => {
    const [expanded, setExpanded] = React.useState('panel0');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [websiteUrl, setWebsiteUrl] = useState('')

    if (websiteUrl === '') {
        getSiteData(setWebsiteUrl)
    }
    return (
        <div className={"resource-panel"}>
            <Accordion defaultExpanded={true} expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Stato importazione</Typography>
                </AccordionSummary>
                <div>
                    <ImportWizard stopTaskStatus={stopSyncSitemapTask} createChain={(it) => createSitemapChain(it)}
                                  getChainStatus={(it) => getSitemapChainStatus(it)}></ImportWizard>
                </div>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>Report</Typography>
                </AccordionSummary>
                <div className={"resource-list-wrapper"}>

                    <List bulkActionButtons={false} exporter={false}  {...props} filters={<SitemapFilter/>}
                          pagination={<RecordPagination/>}
                          title={"Lista dei task"}>
                        <Datagrid>
                            <TextField source="id" sortable={true}/>
                            <DateField label={"date"} source="date" showTime={true} sortable={true}/>
                            <TextField label={"detail"} source="detail" sortable={false}/>
                            <TextField label={"link"} source="link" sortable={false}/>
                            <TextField label={"postId"} source="postId" sortable={false}/>
                            <TextField label={"postType"} source="postType" sortable={true}/>
                            <TextField label={"result"} source="result" sortable={true}/>
                            <TextField label={"count"} source="count" sortable={true}/>
                        </Datagrid>
                    </List>
                </div>
            </Accordion>
        </div>
    );
};