import * as React from "react";
import {useState} from "react";
import {Datagrid, DateField, List, TextField} from "react-admin";
import {Accordion, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import '../common.scss'
import {getSiteData} from "../actions/get";
import RecordPagination from "../utils/pagination/recordpagination";
import ScheduleDatetimeField from "../utils/customfields/ScheduleDatetimeField/ScheduleDatetimeField";

export const FilesManager = props => {
    const [expanded, setExpanded] = React.useState('panel3');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [websiteUrl, setWebsiteUrl] = useState('')

    if (websiteUrl === '') {
        getSiteData(setWebsiteUrl)
    }
    return (
        <div className={"resource-panel"}>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>Report</Typography>
                </AccordionSummary>
                <div className={"resource-list-wrapper"}>

                    <div>
                        <Typography>Importa le immagini</Typography>
                        <ScheduleDatetimeField name={'Upload denworker.com images'} />
                    </div>

                    <List bulkActionButtons={false} exporter={false}  {...props}
                          pagination={<RecordPagination/>}
                          title={"Gestione immagini"}>
                        <Datagrid>
                            <TextField source="id" sortable={true}/>
                            <TextField label={"codice"} source="ref" sortable={false}/>
                            <TextField label={"nome originale"} source="originalName" sortable={false}/>
                            <TextField label={"posizione"} source="position" sortable={false}/>
                            <DateField label={"Data caricamento"} source="updloadTime" showTime={true}
                                       sortable={false}/>
                        </Datagrid>
                    </List>
                </div>
            </Accordion>
        </div>
    );
};