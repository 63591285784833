import * as React from "react";
import {useState} from "react";
import {Datagrid, DateField, List, TextField} from "react-admin";
import {Accordion, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import '../common.scss'
import ScheduledTaskFilter from "../utils/filters/scheduled-task-filter"
import {getSiteData} from "../actions/get";
import StartStopButton from "./denworker/buttons/StartStopButton";
import RecordPagination from "../utils/pagination/recordpagination";

export const ScheduledTasks = props => {
    const [expanded, setExpanded] = React.useState('panel3');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [websiteUrl, setWebsiteUrl] = useState('')

    if (websiteUrl === '') {
        getSiteData(setWebsiteUrl)
    }
    return (
        <div className={"resource-panel"}>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>Report</Typography>
                </AccordionSummary>
                <div className={"resource-list-wrapper"}>

                    <List bulkActionButtons={false} exporter={false}  {...props} filters={<ScheduledTaskFilter/>} sort={{field: 'startTime', order: 'DESC'}}
                          pagination={<RecordPagination/>}
                          title={"Lista dei task"}>
                        <Datagrid>
                            <TextField source="id" sortable={true}/>
                            <TextField label={"nome"} source="name" sortable={true}/>
                            <DateField label={"inizio"} source="startTime" showTime={true} sortable={false} />
                            <DateField label={"fine"} source="endTime" showTime={true} sortable={true}/>
                            <TextField label={"dettagli"} source="resultData" sortable={false}/>
                            <TextField label={"errore"} source="errorDescription" sortable={false}/>
                            <TextField label={"stato"} source="status" sortable={true}/>
                            <TextField label={"job id"} source="jobId" sortable={true}/>
                            <StartStopButton label={"modifica stato"} source="status" sortable={false}/>
                        </Datagrid>
                    </List>
                </div>
            </Accordion>
        </div>
    );
};