import React from 'react'
import {List} from "@material-ui/core";
import SingleFile from "./singlefile";

export default function  FileList(props) {
    let html = []
    props.files.map((file) => {
        html.push(
            <div>
                <SingleFile accepted={props.accepted} file={file} successEndpoint={props.successEndpoint}></SingleFile>
            </div>
        )
    })
    return <div>
        <List>{html}</List>
    </div>
}
