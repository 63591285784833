import * as React from "react";
import {useEffect} from "react";
import './chained-job-overview.scss'
import StartStopButton from "../denworker/buttons/StartStopButton";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

export const ChainedJobOverview = (props) => {

    const updateChainData = (it) => {
        setChainStatus(it.scheduleDataList)
        props.inProgressCallback(it.inProgress)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            props.getChainStatus((it) => updateChainData(it))
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const [chainStatus, setChainStatus] = React.useState([])
    let list = []
    let status_class
    chainStatus.forEach((it) => {
        switch (it.status) {
            case 'in corso':
                status_class = 'progress'
                break;
            case 'completato':
                status_class = 'complete'
                break;
            case 'interrotto':
                status_class = 'interrupted'
                break;
            case 'pianificato':
                status_class = 'planned'
                break;
            case 'errore':
                status_class = 'error'
                break;
            case 'annullato':
                status_class = 'suspended'
                break;
            default:
                status_class = 'inactive'
        }
        list.push(
            <TableRow key={it.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell>{it.name}</TableCell>
                <TableCell>{it.startTime === null ? '' : (new Date(it.startTime).toLocaleDateString() + " " + new Date(it.startTime).toLocaleTimeString())}</TableCell>
                <TableCell>{it.endTime === null ? '' : (new Date(it.endTime).toLocaleDateString() + " " + new Date(it.endTime).toLocaleTimeString())}</TableCell>
                <TableCell>{it.resultData}</TableCell>
                <TableCell>{it.status}</TableCell>
                <TableCell><StartStopButton stopTaskStatus={props.stopTaskStatus} label={"modifica stato"}
                                            source={'status'} sortable={false}
                                            record={it}/></TableCell>
            </TableRow>
        )
    });
    let scheduleData = ''
    if (chainStatus.length > 0) {
        scheduleData = <div key={'chained-job-overview-wrapper'} className={'chained-job-overview'}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">Operazione</TableCell>
                            <TableCell>Data inizio</TableCell>
                            <TableCell>Data fine</TableCell>
                            <TableCell>Risultato</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Azione</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>;
    }
    return scheduleData
}