import {Filter, SelectInput, TextInput} from "react-admin";
import * as React from "react";

export const COMPLETED = "completato"
export const ERROR = "errore"
export const EXPIRED = "scaduto"
export const INTERRUPTED = "interrotto"
export const SCHEDULED = "pianificato"
export const SUSPENDED = "annullato"
export const PROGRESS = "in corso"
export const ARCHIVED = "archiviato"
export const DELETE = "cancella"
export const STOPPING = "in arresto"

export default function ScheduledTaskFilter(props){
    return (
        <Filter {...props}>
            <SelectInput source="status" alwaysOn choices={[
                {id:COMPLETED, name: COMPLETED},
                {id: SCHEDULED, name: SCHEDULED},
                {id:SUSPENDED, name: SUSPENDED},
                {id:EXPIRED, name: EXPIRED},
                {id:PROGRESS, name: PROGRESS},
                {id:ERROR, name: ERROR},
                {id:ARCHIVED, name: ARCHIVED},
                {id:STOPPING, name: STOPPING},
            ]
            }/>
        </Filter>
    );
}