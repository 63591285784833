import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Schedule} from "@material-ui/icons";
import {ActionButton, primaryStyle} from "../../buttons/ActionButton";
import {createTaskStatus} from "../../../actions/post";
import {FAILED, INACTIVE, LoadingIcon, PROGRESS, SUCCESS} from "../../loading/LoadingIcon";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const zeroPrefix = (val, adjust) => {
    let str = val + (adjust === undefined ? 0 : adjust)
    return (str) < 10 ? "0" + str : str
}

const addMinute = (date, addValue) => {
    let minutes = date.getMinutes();
    let addedMinutes = zeroPrefix(minutes + addValue);
    let hours = zeroPrefix(date.getHours());
    let days = zeroPrefix(date.getDate());
    let months = zeroPrefix(date.getMonth(), addValue);
    let year = date.getFullYear();
    let newDate = new Date(year + '-' + months + '-' + days + 'T' + hours + ':' + addedMinutes);
    return newDate;
}

export default function ScheduleDatetimeField(props) {
    const [actionStatus, setActionStatus] = React.useState(INACTIVE);
    const [errorDetail, setErrorDetail] = useState('')
    const [selectedDate, handleDateChange] = useState(addMinute(new Date(), 1));
    const [successDetail, setSuccessDetail] = useState("");
    const [remoteDate, setRemoteDate] = useState(addMinute(new Date(), 1));
    const classes = useStyles();
    setTimeout(function () {
        let time = new Date().getTime();
        if (selectedDate.getTime() <= time) {
            handleDateChange(new Date(time))
        }
    }, 1000 * 1000);
    const updateStartDate = (date) => {
        let now = new Date();
        let scheduleTime = date;
        if (scheduleTime.getTime() <= now.getTime()) {
            scheduleTime = addMinute(now, 1);
        }
        handleDateChange(scheduleTime)
    }
    const createTask = () => {
        setActionStatus(PROGRESS)
        let date = {
            name: props.name,
            starttime: selectedDate.getTime(),
            parameters: props.params
        };
        createTaskStatus(date, (status, response) => {
            if (status === SUCCESS) {
                setSuccessDetail(response.message)
            } else {
                setErrorDetail(response)
            }
            setActionStatus(status)
        })
    }
    return (
        <div className={"schedule-datetime-wrapper"}>
            <div>
                <form className={classes.container} noValidate>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                        <DateTimePicker
                            label="Avvia il"
                            format={'dd/MM/yyyy HH:mm:ss'}
                            className={classes.textField}
                            value={selectedDate}
                            onChange={updateStartDate}
                            minDate={remoteDate}
                            minDateMessage={'La data deve essere successiva a quella attuale'}
                            disablePast={true}
                        />
                    </MuiPickersUtilsProvider>
                    <ActionButton label={"SALVA"} action={createTask} icon={<Schedule/>}
                                  style={primaryStyle}></ActionButton>
                </form>
                <div>
                    <LoadingIcon actionStatus={actionStatus}/>
                    <div className={'feedback-wrapper'}>
                        <div
                            className={"result-feedback success-result " + (actionStatus === SUCCESS ? '' : 'hide-btn')}>
                            {successDetail}
                        </div>
                        <div className={"result-feedback failed-result " + (actionStatus === FAILED ? '' : 'hide-btn')}>
                            {errorDetail}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}