import * as React from "react";
import PropTypes from 'prop-types';
import {useRecordContext} from 'react-admin';
import './attributefield.scss'
import {CheckCircle, ErrorOutline} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core";

const ChannablePublished = (props) => {
    const useStyles = makeStyles({
        done: {
            color: 'green',
            margin: '1px'
        },
        error: {
            color: 'red',
            margin: '1px'
        }
    });
    const classes = useStyles();
    const record = useRecordContext(props);
    let status = '';
    if (props.condition(record)) {
        status = <CheckCircle className={classes.done} />
    } else {
        status = <ErrorOutline className={classes.error} />
    }
    return status;
}

ChannablePublished.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default ChannablePublished;