import * as React from "react";
import {useState} from "react";
import './ImportExcelPanel.scss'
import '../../common.scss'
import {FAILED, LoadingIcon, SUCCESS} from "../../utils/loading/LoadingIcon";
import SkuListTextArea from "../../utils/customfields/skuListTextArea";
import {apiVersion} from "../../../config/exchange";
import ScheduleDatetimeField from "../../utils/customfields/ScheduleDatetimeField/ScheduleDatetimeField";
import {Typography} from "@material-ui/core";
import {ActionButton} from "../../utils/buttons/ActionButton";
import {importFile} from "../../actions/post";
import {Publish} from "@material-ui/icons";

export default function ImportExcelPanel(props) {
    const [skuList, setSkuList] = useState([])
    const [imported, setImported] = useState({})
    const [errorDetail, setErrorDetail] = useState('')
    // const [successDetail, setSuccessDetail] = useState('')

    const importStatusCallback = (e, errorDetail) => {
        setImported(e)
        setErrorDetail(errorDetail)
    }
    let endpoint;
    let skuSelection = ""
    if (undefined !== props.successEndpoint) {
        endpoint = props.successEndpoint
    } else {
        skuSelection = <SkuListTextArea
            label={'Inserisci la lista degli sku da IMPORTARE separata da virgole (,) oppure lascia vuoto per importarli tutti'}
            callback={(e) => setSkuList(e)}></SkuListTextArea>
        endpoint = apiVersion + '/excel'
    }
    return <div className={"import-excel-data-wrapper"}>
        <div className={"import-excel-file-button-wrapper"}>
            {/*{skuSelection}*/}
            {/*<Typography>Importa i prodotti dal file</Typography>*/}
            {/*{<ScheduleDatetimeField name={'Import excel file'} params={JSON.stringify(skuList)}/>}*/}
            {/*<ActionButton label={'Importa'} action={() => importFile(skuList, endpoint, importStatusCallback)} icon={<Publish />} />*/}
        </div>
        <div>
            <LoadingIcon actionStatus={imported}></LoadingIcon>
        </div>
        <div className={"result-feedback success-result " + (imported === SUCCESS ? '' : 'hide-btn')}>
            I dati del file sono stati importati con successo
        </div>
        <div className={"result-feedback failed-result " + (imported === FAILED ? '' : 'hide-btn')}>
            {errorDetail}
        </div>
    </div>;
}