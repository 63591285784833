import {_get, getFile, post} from "../utils/rest/RestClient";
import {FAILED, PROGRESS, SUCCESS} from "../utils/loading/LoadingIcon";
import {apiVersion} from "../../config/exchange";

export const getSitemapChainStatus = (callback) => {
    _get(`${apiVersion}/job/sync-sitemap/status`)
        .then(function (response) {
            callback(response.data.details)
        })
        .catch(function (error) {
            if (error.status) {
                callback(error.response.data.details)
            } else {
                callback(error.message)
            }
        })
};

export const getProductsChainStatus = (callback) => {
    _get(`${apiVersion}/job/sync-products/status`)
        .then(function (response) {
            callback(response.data.details)
        })
        .catch(function (error) {
            if (error.status) {
                callback(error.response.data.details)
            } else {
                callback(error.message)
            }
        })
};

export const getCustomersChainStatus = (callback) => {
    _get(`${apiVersion}/job/sync-customers/status`)
        .then(function (response) {
            callback(response.data.details)
        })
        .catch(function (error) {
            if (error.status) {
                callback(error.response.data.details)
            } else {
                callback(error.message)
            }
        })
};

export const getSiteData = (callback) => {
    _get(`${apiVersion}/website`)
        .then(function (response) {
            let url = response.data.details.scheme + "://" + response.data.details.host
            callback(url)
        })
        .catch(function (error) {
            if (error.status) {
                callback(error.response.data.details)
            } else {
                callback(error.message)
            }
        })
};

export const getImagesList = (endpoint, callback) => {
    let initValue = []
    callback(PROGRESS, initValue)
    _get(`${apiVersion + endpoint}`)
        .then(function (response) {
            callback(SUCCESS, response.data.details)
        })
        .catch(function (error) {
            callback(FAILED, initValue)
        })
}

export const getDeleteList = (callback) => {
    let skuList = []
    callback(PROGRESS)
    _get(`${apiVersion}/website/delete`)
        .then(function (response) {
            if (Array.isArray(response.data.details)) {
                response.data.details.forEach(function (e) {
                    skuList.push(e.sku)
                })
            } else {
                skuList = []
            }
            callback(SUCCESS, response.data.details)
        })
        .catch(function (error) {
            callback(FAILED, error)
        })
}

export const getRestoreStagingStatus = (callback) => {
    _get(`${apiVersion}/admin/restore-staging`)
        .then(function (response) {
            callback(response.data.details)
        })
        .catch(function (error) {
            if (error.status) {
                callback(error.response.data.details)
            } else {
                callback(error.message)
            }
        })
};

export const downloadProductsFile = (callback) => {
    getFile(`${apiVersion}/excel/file/products`)
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            callback({status: 404, message: "file not found", details: {}});
        })
}

export const updateTenantRequest = (tenantId, callback) => {
    const endpoint = `${apiVersion}/tenant/${tenantId}`;
    callback(PROGRESS, '')
    _get(`${endpoint}`, callback)
        .then(function (response) {
            callback(SUCCESS, response.data.status, response.data.message)
        })
        .catch(function (error) {
            callback(FAILED, error.response.status, error.response.data.message)
        });
}