import * as React from "react";
import {useState} from "react";
import {Accordion, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import '../common.scss'
import {ActionButton} from "../utils/buttons/ActionButton";
import {SyncWizard} from "./sync-wizard";
import {restoreStaging} from "../actions/post";
import {getRestoreStagingStatus} from "../actions/get";

export const StagingData = props => {
    const [expanded, setExpanded] = React.useState('panel0');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [inProgress, setInProgress] = useState(true)
    let actionButton;
    if (inProgress) {
        actionButton = 'Importazione in corso, cancella l\'importazione per avviarne un\'altra';
    } else {
        actionButton = <ActionButton label={'Avvia'}
                                     action={() => props.createChain((status) => setInProgress(status))}></ActionButton>;
    }

    return (
        <div className={"resource-panel"}>
            <Accordion expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                <AccordionSummary expandIcon={<ExpandMore/>} aria-controls="panel0a-content" id="panel0a-header">
                    <Typography>Sincronizza staging con i dati di produzione</Typography>
                </AccordionSummary>
                <div className={"resource-list-wrapper"}>
                    <div>
                        <SyncWizard createCommandList={(it) => restoreStaging(it)}  getCommandListStatus={(it) => getRestoreStagingStatus(it)}></SyncWizard>
                    </div>
                </div>
            </Accordion>
        </div>
    );
};