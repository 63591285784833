import * as React from "react";
import {Admin, Resource} from 'react-admin';
import {DenworkerUploadList} from "./components/resources/denworker-upload";
import {ProductList} from "./components/resources/excel-products";
import {CustomersList} from "./components/resources/excel-customers";
import dataProvider from './components/dataprovider/dataprovider'
import wordpressAuthProvider from "./components/resources/wordpressAuthProvider";
import {ScheduledTasks} from "./components/resources/scheduled-tasks";
import {FilesManager} from "./components/resources/files-manager";
import {ChannableData} from "./components/resources/ChannableData";
import {StagingData} from "./components/resources/StagingData";
import {SitemapEntries} from "./components/resources/sitemap-entries";
import CustomLoginPage from "./components/login/CustomLoginPage";
import ExchangeLayout from "./components/layout/ExchangeLayout";

const userPanels = [
    <Resource key={"api/v1/website/downloaded"} name={"api/v1/website/downloaded"} options={{label: 'Importazione prodotti'}} list={ProductList}/>,
    <Resource key={"api/v1/customer"} name={"api/v1/customer"} options={{label: 'Importazione clienti'}} list={CustomersList}/>,
    <Resource key={"api/v1/sitemap"} name={"api/v1/sitemap"} options={{label: 'Generazione Sitemap'}} list={SitemapEntries}/>,
    <Resource key={"api/v1/channable"} name={"api/v1/channable"} options={{label: 'Channable'}} list={ChannableData}/>,
    <Resource key={"api/v1/staging"} name={"api/v1/staging"} options={{label: 'Sincronizzazione'}} list={StagingData}/>
]

const adminPanels = [
    <Resource key={"api/v1/website/uploaded"} name={"api/v1/website/uploaded"} options={{label: 'Importa sul sito'}} list={DenworkerUploadList}/>,
    // <Resource name={"api/v1/website/downloaded"} options={{label: 'On-line sul sito'}} list={DenworkerOnlineProducts}/>,
    <Resource key={"api/v1/images"} name={"api/v1/images"} options={{label: 'Gestione immagini'}} list={FilesManager}/>,
    <Resource key={"api/v1/schedule"} name={"api/v1/schedule"} options={{label: 'Operazioni pianificate'}} list={ScheduledTasks}/>
]


const App = () => {
    let adminPanelsList
    let userData = localStorage.getItem('userdata');
    if (userData !== null && JSON.parse(userData).user_email === 'davide.taddei@gmail.com') {
        adminPanelsList = adminPanels
    } else {
        adminPanelsList = []
    }
    return <div key={"admin-app-wrapper"}>
        <Admin key={"admin-app"} layout={ExchangeLayout} loginPage={CustomLoginPage} authProvider={wordpressAuthProvider} dataProvider={dataProvider}>
            {userPanels}
            {adminPanelsList}
        </Admin>;
    </div>
}

export default App;