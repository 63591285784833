import * as React from "react";
import {useState} from "react";
import {Datagrid, DateField, downloadCSV, List, Pagination, TextField} from 'react-admin';
import {Accordion, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import '../common.scss'
import DropUI from "../utils/upload/DropUI";
import {EXCEL_MIME, JPEG_MIME, ZIP_MIME} from "../../config/filetypes";
import {apiVersion} from "../../config/exchange";
import {ImportWizard} from "./import-wizard";
import {DeleteRemoteList} from "./denworker/deleteRemoteList";
import DenworkerComFilter from "../utils/filters/denworker-com-filter";
import {GotoButton} from "./denworker/buttons/GotoButton";
import {getProductsChainStatus, getSiteData} from "../actions/get";
import jsonExport from 'jsonexport/dist';
import {createProductsChain} from "../actions/post";
import {stopSyncProductsTask, stopSyncSitemapTask} from "../actions/put";

const uploadImagesEndpoint = `${apiVersion}/images/batch`
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;
const acceptedProductsUpload = [EXCEL_MIME];
const acceptedImagesUpload = [JPEG_MIME];
const acceptedImagesZipUpload = [ZIP_MIME];
export const ProductList = props => {
    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const exporter = products => {
        const postsForExport = products.map(product => {
            const {
                ref,
                type,
                stock,
                weight,
                width,
                height,
                length,
                tagNames,
                description,
                shortdescription,
                volume,
                packages,
                categories,
                saleprice,
                published,
                price,
                upSell,
                upSellQty,
                ean,
                amazonCategory,
                amazonFullfillmentLatency,
                id,
                updateTime,
                ...productForExport
            } = product;
            return productForExport;
        });
        jsonExport(postsForExport, {
            headers: ['sku', 'name', 'images', 'status'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'prodotti'); // download as 'posts.csv` file
        });
    };

    const [websiteUrl, setWebsiteUrl] = useState('')

    if (websiteUrl === '') {
        getSiteData(setWebsiteUrl)
    }
    return (
        <div className={"resource-panel"}>
            <Accordion defaultExpanded={true} expanded={expanded === 'panel0'} onChange={handleChange('panel0')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Stato importazione</Typography>
                </AccordionSummary>
                <div>
                    <ImportWizard stopTaskStatus={stopSyncProductsTask} createChain={(it) => createProductsChain(it)}
                                  getChainStatus={(it) => getProductsChainStatus(it)}
                                  downloadFile={true}></ImportWizard>
                </div>
            </Accordion>
            <Accordion defaultExpanded={true} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Carica file Prodotti</Typography>
                </AccordionSummary>
                <div>
                    <DropUI accepted={acceptedProductsUpload} single={true}
                            endpoint={`${apiVersion + '/excel/upload'}`}></DropUI>
                </div>
            </Accordion>
            <Accordion defaultExpanded={true} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Carica file immagini</Typography>
                </AccordionSummary>
                <div>
                    <div className={"section-wrapper"}>
                        <h3>Carica le immagini in un unico file ZIP</h3>
                    </div>
                    <DropUI accepted={acceptedImagesZipUpload} single={true}
                            endpoint={`${apiVersion + '/images/archive'}`}
                            successEndpoint={uploadImagesEndpoint}></DropUI>
                </div>
                <div>
                    <div className={"section-wrapper"}>
                        <h3>Carica le singole immagini</h3>
                    </div>
                    <DropUI accepted={acceptedImagesUpload} single={false}
                            endpoint={`${apiVersion + '/images/save'}`}></DropUI>
                </div>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                >
                    <Typography>Cancella su Denworker</Typography>
                </AccordionSummary>
                <DeleteRemoteList></DeleteRemoteList>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>Report</Typography>
                </AccordionSummary>
                <div className={"resource-list-wrapper"}>

                    <List bulkActionButtons={false} pagination={<PostPagination/>} exporter={exporter} {...props}
                          filters={<DenworkerComFilter/>}
                          title={"Lista dei prodotti caricati sul sito"}>
                        <Datagrid>
                            <TextField source="id" sortable={true}/>
                            <TextField source="ref" sortable={false}/>
                            <TextField source="name" sortable={false}/>
                            <TextField source="images" sortable={true}/>
                            <TextField source="status"/>
                            <DateField source="updateTime" showTime={true} sortable={true}/>
                            <div className={(websiteUrl !== '' ? '' : 'hide-btn')}>
                                <GotoButton label={'APRI'}
                                            action={(e) => window.open(websiteUrl + '?s=' + e.ref, '_blank')}/>
                            </div>
                        </Datagrid>
                    </List>
                </div>
            </Accordion>
        </div>
    );
};