import * as React from "react";
import {useState} from "react";
import {ActionButton} from "../utils/buttons/ActionButton";
import {Container, Paper} from "@material-ui/core";
import './import-wizard.scss'
import {RestoreCommandList} from "./import-wizard/restore-command-list";

export const SyncWizard = (props) => {
    const [inProgress, setInProgress] = useState(true)
    let actionButton;
    if (inProgress) {
        actionButton = 'Importazione non completa attendere per avviarne un\'altra';
    } else {
        actionButton = <ActionButton label={'Avvia'} action={() => {setInProgress(true);props.createCommandList((status) => setInProgress(status))}}></ActionButton>;
    }
    return <div>
        <Container>
            <Paper elevation={0}>
                <div className={"resource-panel"}>
                    <div>
                        <RestoreCommandList getCommandListStatus={(it)=>props.getCommandListStatus(it)} inProgress={inProgress}
                                            inProgressCallback={(status) => setInProgress(status)}></RestoreCommandList>
                        <div className={'wrapper'}>
                            <Container>
                                {actionButton}
                            </Container>
                        </div>
                    </div>
                </div>
            </Paper>
        </Container>
    </div>
}