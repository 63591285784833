import {Filter, ReferenceInput, SelectInput, TextInput} from "react-admin";
import * as React from "react";

export default function denworkercomfilter(props){
    return (
        <Filter {...props}>
            <TextInput label="Cerca per codice" source="ref" alwaysOn/>
            <SelectInput source="status" alwaysOn choices={[
                {id:'Success', name: 'Success'},
                {id:'Failed', name: 'Failed'},
                {id:'Deleted', name: 'Deleted'}
            ]
            }/>
        </Filter>
    );
}