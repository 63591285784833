import {_delete} from "../utils/rest/RestClient";
import {FAILED, INACTIVE, PROGRESS, SUCCESS} from "../utils/loading/LoadingIcon";
import {apiVersion} from "../../config/exchange";
import responsive from "@iconify-icons/mdi/responsive";

export const deleteTask = (object, callback) => {
    const endpoint = `${apiVersion}/schedule/`
    _delete(`${endpoint}${object.id}`, callback)
        .then(function (response) {
            callback(SUCCESS, '')
        })
        .catch(function (error) {
            callback(FAILED, error)
        });
}

export const deleteproduct = (id, callback) => {
    const endpoint = `${apiVersion}/excel`
    _delete(id === undefined?`${endpoint}`:`${endpoint}/${id}`, callback)
        .then(function (response) {
            callback(SUCCESS)
        })
        .catch(function (error) {
            callback(FAILED)
        });
}

export const deleteAllExcelProducts = (callback) => {
    deleteproduct(undefined, callback)
}

export const refreshChannableCacheAction = (callback) => {
    const endpoint = `${apiVersion}/channable/cache`;
    callback(PROGRESS, '')
    _delete(`${endpoint}`, callback)
        .then(function (response) {
            callback(SUCCESS, response.data.status, response.data.message)
        })
        .catch(function (error) {
            callback(FAILED, error.response.status, error.response.data.message)
        });
}

export const deleteAllDenworkerProducts = (callback) => {
    const endpoint = `${apiVersion}/website/uploaded`;
    _delete(`${endpoint}`, callback)
        .then(function (response) {
            callback(SUCCESS)
        })
        .catch(function (error) {
            callback(FAILED)
        });
}

export const deleteDenworkerOldProducts = (callback) => {
    callback(PROGRESS, [])
    _delete(`${apiVersion}/website/missingproducts`, callback)
        .then(function (response) {
            let skuList = []
            if (Array.isArray(response.data.details)) {
                response.data.details.forEach(function (e) {
                    skuList.push(e.sku)
                })
            } else {
                skuList = []
            }
            callback(SUCCESS, INACTIVE)
        })
        .catch(function (error) {
            callback(FAILED, INACTIVE)
        })
        .then(function () {
        });
}