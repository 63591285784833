import React from 'react'
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {AllInbox, Error, InsertDriveFile, ListAlt} from "@material-ui/icons";
import UploadFeedback from "./uploadfeedback";
import {EXCEL_MIME, JPEG_MIME, ZIP_MIME} from "../../../config/filetypes";

const uploadImageEndpoint = `/denworker/image/upload`;
const uploadExcelEndpoint = `/denworker/excel/upload`;

export default function SingleFile(props) {
    let icon
    if (props.accepted.includes(props.file.data.type)) {
        let endpoint = '';
        switch (props.file.data.type) {
            case EXCEL_MIME:
                icon = <ListAlt style={{fontSize: 40, color: 'green'}}/>
                endpoint = uploadExcelEndpoint
                break;
            case JPEG_MIME:
                icon = <img style={{
                    'max-width': '300px',
                    'padding': '10px',
                    'border': '1px solid black',
                    'margin-right': '20px'
                }} src={URL.createObjectURL(props.file.data)}/>
                endpoint = uploadImageEndpoint
                break;
            case ZIP_MIME:
                icon = <AllInbox style={{fontSize: 40, color: '#e9df0e'}}/>
                endpoint = uploadImageEndpoint
                break;
            default:
                icon = <InsertDriveFile style={{fontSize: 40, color: 'yellow'}}/>
        }
    } else {
        icon = <Error style={{fontSize: 40, color: 'red'}}/>
    }
    return <div>
        <ListItem>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={props.file.data.name}
                secondary={props.file.data.type}
            />
        </ListItem>
        {<UploadFeedback filetype={props.file.data.type} uploaded={props.file.status}
                         uploadedmessage={props.file.message} successEndpoint={props.successEndpoint} />}
    </div>
}