import * as React from "react";
import PropTypes from 'prop-types';
import {useRecordContext} from 'react-admin';
import {ActionButton, primaryStyle} from "../../../utils/buttons/ActionButton";
import {Send} from "@material-ui/icons";
import {FAILED, INACTIVE, LoadingIcon, SUCCESS} from "../../../utils/loading/LoadingIcon";
import './UpdateButton.scss'

const UpdateButton = (props) => {
    const record = useRecordContext(props);
    const [uploadActionStatus, setUploadActionStatus] = React.useState(INACTIVE);
    const onClick = () => {
        props.action(record.ref, (i) => {
            setUploadActionStatus(i)
        });
    }
    let feedback = ''
    switch (uploadActionStatus) {
        case FAILED:
            feedback = <div className={'result-feedback failed-result'}>Aggiornamento fallito</div>
            break;
        case SUCCESS:
            feedback = <div className={'result-feedback success-result'}>Aggiornamento effettuato</div>
            break;
    }
    return <div className={'update-button-toolbar-wrapper'}>
        <div className={'update-button-toolbar'}>
            <div className={'update-button-toolbar-button'}>
                <ActionButton icon={<Send/>} label={'Aggiorna'} action={onClick} style={primaryStyle}/>
            </div>
            <div className={'update-button-toolbar-loading'}>
                <LoadingIcon actionStatus={uploadActionStatus}></LoadingIcon>
            </div>
        </div>
        <div className={'update-button-toolbar-feeback'}>{feedback}</div>
    </div>
}

UpdateButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
};

export default UpdateButton;