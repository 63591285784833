import * as React from "react";
import {useState} from "react";
import {ChainedJobOverview} from "./import-wizard/chained-job-overview";
import {ActionButton} from "../utils/buttons/ActionButton";
import {Container, Paper} from "@material-ui/core";
import './import-wizard.scss'
import {CheckFileIsAvailable} from "./CheckFileIsAvailable";

export const ImportWizard = (props) => {
    const [inProgress, setInProgress] = useState(true)
    let actionButton;
    if (inProgress) {
        actionButton = 'Importazione in corso, cancella l\'importazione per avviarne un\'altra';
    } else {
        actionButton = <ActionButton label={'Avvia'}
                                     action={() => props.createChain((status) => setInProgress(status))}></ActionButton>;
    }
    let downloadFile
    if (props.downloadFile) {
        downloadFile = <div id={"button-right-wrapper"} style={{display: 'inline-block', padding: '10px'}}>
            <CheckFileIsAvailable downloadFile={props.downloadFile}></CheckFileIsAvailable>
        </div>;
    } else {
        downloadFile = ''
    }
    return <div>
        <Container>
            <Paper elevation={0}>
                <div className={"resource-panel"}>
                    <div>
                        <ChainedJobOverview stopTaskStatus={props.stopTaskStatus}
                                            getChainStatus={(it) => props.getChainStatus(it)} inProgress={inProgress}
                                            inProgressCallback={(status) => setInProgress(status)}></ChainedJobOverview>
                        <div className={'wrapper'}>
                            <Container>
                                <div id={"buttons-wrapper"}>
                                    <div id={"button-left-wrapper"} style={{display: 'inline-block', padding: '10px'}}>
                                        {actionButton}
                                    </div>
                                    {downloadFile}
                                </div>
                            </Container>
                        </div>
                    </div>
                </div>
            </Paper>
        </Container>
    </div>
}