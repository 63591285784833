import {Filter, SelectInput, TextInput} from "react-admin";
import * as React from "react";

export const POST_TYPE_POST = "post"
export const POST_TYPE_PAGE = "page"
export const POST_TYPE_PRODUCT = "product"
export const POST_TYPE_FURGONE = "furgoni"

export default function SitemapFilter(props){
    return (
        <Filter {...props}>
            <SelectInput source="postType" alwaysOn choices={[
                {id:POST_TYPE_POST, name: POST_TYPE_POST},
                {id: POST_TYPE_PAGE, name: POST_TYPE_PAGE},
                {id:POST_TYPE_PRODUCT, name: POST_TYPE_PRODUCT},
                {id:POST_TYPE_FURGONE, name: POST_TYPE_FURGONE}
            ]
            }/>
        </Filter>
    );
}