import * as React from "react";
import {useEffect} from "react";
import './chained-job-overview.scss'
import StartStopButton from "../denworker/buttons/StartStopButton";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

export const RestoreCommandList = (props) => {

    const updateChainData = (it) => {
        setRestoreStatus(it.commandDataList)
        props.inProgressCallback(it.inProgress)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            props.getCommandListStatus((it) => updateChainData(it))
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const [restoreStatus, setRestoreStatus] = React.useState([])
    let list = []
    let status_class
    restoreStatus.forEach((it) => {
        switch (it.status) {
            case 'in corso':
                status_class = 'progress'
                break;
            case 'completato':
                status_class = 'complete'
                break;
            case 'interrotto':
                status_class = 'interrupted'
                break;
            case 'pianificato':
                status_class = 'planned'
                break;
            case 'errore':
                status_class = 'error'
                break;
            case 'annullato':
                status_class = 'suspended'
                break;
            default:
                status_class = 'inactive'
        }
        list.push(
            <TableBody>
                <TableRow key={it.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell>{it.id}</TableCell>
                    <TableCell>{it.command}</TableCell>
                    <TableCell>{it.exitStatus}</TableCell>
                    <TableCell>{it.result}</TableCell>
                    <TableCell>{it.sessionId}</TableCell>
                    <TableCell>{it.timestamp === null ? '' : (new Date(it.timestamp).toLocaleDateString() + " " + new Date(it.timestamp).toLocaleTimeString())}</TableCell>
                    <TableCell>{it.status}</TableCell>
                </TableRow>
            </TableBody>
        )
    });
    let scheduleData = ''
    if (restoreStatus.length > 0) {
        scheduleData = <div className={'chained-job-overview'}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">Operazione</TableCell>
                            <TableCell>Data inizio</TableCell>
                            <TableCell>Data fine</TableCell>
                            <TableCell>Risultato</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Azione</TableCell>
                        </TableRow>
                    </TableHead>
                    {list}
                </Table>
            </TableContainer>
        </div>;
    }
    return scheduleData
}