import * as React from "react";
import {useState} from "react";
import {Delete} from "@material-ui/icons";
import {FAILED, INACTIVE, LoadingIcon, SUCCESS} from "../../utils/loading/LoadingIcon";
import {ActionButton, secondaryStyle} from "../../utils/buttons/ActionButton";
import {deleteDenworkerOldProducts} from "../../actions/delete";
import {getDeleteList} from "../../actions/get";
import GetDeleteListButton from "./buttons/GetDeleteListButton";
import ReadOnlySkuList from "../../utils/customfields/ReadOnlySkuList";
import './deleteRemoteList.scss'

export const DeleteRemoteList = () => {
    const [skuList, setSkuList] = useState([])
    const [listed, setListed] = useState({})
    const [deleted, setDeleted] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [actionStatus, setActionStatus] = useState(INACTIVE)
    const importStatusCallback = (status, detail) => {
        let actionStatus
        if (status === FAILED) {
            switch (detail.response.status) {
                case 400:
                    setErrorMessage('La richiesta non è corretta')
                    break
                case 401:
                    setErrorMessage('Mancano i permessi per accedere alla risorsa')
                    break
                case 404:
                    setErrorMessage('Risorsa non trovata')
                    break
                case 502:
                    setErrorMessage('Connessione al sito non disponibile')
                    break
                default:
                    setErrorMessage('Errore interno del server')
            }
            setSkuList([])
            actionStatus = status
        } else if (status === SUCCESS) {
            setSkuList(detail)
            actionStatus = status
        } else {
            actionStatus = INACTIVE
        }
        setListed(status)
        setDeleted(INACTIVE)
        setActionStatus(actionStatus)
    }
    let deleteSkuList
    if (skuList.length > 0) {
        deleteSkuList = <div>
            <div>
                <h5>Lista dei codici da cancellare</h5>
            </div>
            <div className={"skulist-count"}>
                Totale codici da cancellare: {skuList.length}
            </div>
            <div>
                <ReadOnlySkuList source={skuList}/>
            </div>
        </div>;
    } else {
        deleteSkuList = <div>Nessun articolo da cancellare</div>
    }
    let toolbar = ''
    if (listed === SUCCESS && skuList.length > 0) {
        toolbar = <div className={"result-feedback " + (listed === SUCCESS ? '' : 'hide-btn')}>
            <ActionButton label={'Cancella i codici'} style={secondaryStyle}
                          action={() => deleteDenworkerOldProducts((deleted, listed) => {
                              setDeleted(deleted)
                              setListed(listed)
                          })} icon={<Delete/>}/>
        </div>;
    }
    const getDeleteListAction = (callback) => {
        setErrorMessage('')
        getDeleteList(callback)
    }
    return (
        <div>
            <div className={"delete-remote-wrapper"}>
                <div className={"resource-list-wrapper"}>

                    <div>
                        <div><GetDeleteListButton label={'Mostra i Codici'}
                                                  action={() => getDeleteListAction(importStatusCallback)}/></div>
                        <div><LoadingIcon actionStatus={actionStatus === undefined ? actionStatus : INACTIVE}
                                          description={'Scarico i prodotti dal sito web'}></LoadingIcon></div>
                        <div
                            className={'result-feedback failed-result ' + (errorMessage !== '' ? '' : 'hide-btn')}>{errorMessage}</div>
                        <div
                            className={"result-feedback " + (listed === SUCCESS ? '' : 'hide-btn')}>{deleteSkuList}<br/>{toolbar}
                        </div>
                        <div
                            className={"result-feedback success-result " + (deleted === SUCCESS ? '' : 'hide-btn')}>Prodotti
                            cancellati
                        </div>
                        <div
                            className={"result-feedback failed-result " + (deleted === FAILED ? '' : 'hide-btn')}>Errore
                            nella cancellazione
                        </div>
                    </div>

                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    )
}