import {makeStyles, TextareaAutosize} from "@material-ui/core";
import * as React from "react";
import './skuListTextArea.scss'

const _ = require('lodash');

const explodeSkuList = (e) => {
    let skuList = []
    _.forEach(_.split(e, ','), (e) => {
        let value = e.trim();
        let isValid = new RegExp('^[0-9A-Z]+$').test(value);
        if (value !== '' && isValid) {
            return skuList.push(value)
        }
    });
    return skuList
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1)
        }
    },
    textarea: {
        resize: "both"
    }
}));

export default function SkuListTextArea(props) {
    const classes = useStyles();

    const skuCallback = (e) => {
        let skuList = explodeSkuList(e)
        props.callback(skuList)
    }

    return <div>
        <h5>{props.label}</h5>
        <TextareaAutosize className={classes} onChange={(e) => skuCallback(e.target.value)}
                          aria-label="minimum height" rowsMin={10}
                          placeholder="D101010101020102, D101010101020103, D101010101020104"
                          style={{width: '100%'}}
                          value={props.value}
        />
    </div>;
}