import {apiUrl} from "../../config/exchange";
import {getTenantData} from "../dataprovider/tenantService";

export default {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        let website = getTenantData();
        if(!website){
            return Promise.reject("Website è un campo obbligatorio");
        }
        let value = { "username": username, "password": password, "website": website };
        const request = new Request( apiUrl + '/login', {
            method: 'POST',
            body: JSON.stringify(value),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('token', auth.token)
                localStorage.setItem('userdata', JSON.stringify({'id': auth.details.data.ID, 'name': auth.details.data.display_name, 'user_email': auth.details.data.user_email}))
            })
            .catch((e) => {
                console.log(e)
                throw new Error('Login fallita')
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('token')
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),

    getIdentity: () => {
        try {
            const { id, fullName, email } = JSON.parse(localStorage.getItem('userdata'));
            return Promise.resolve({ id, fullName, email });
        } catch (error) {
            return Promise.reject(error);
        }
    }


};