import * as React from "react";
import PropTypes from 'prop-types';
import {useRecordContext, useRefresh} from 'react-admin';
import {FAILED, INACTIVE, LoadingIcon, SUCCESS} from "../../../utils/loading/LoadingIcon";
import './UpdateButton.scss'
import {
    Archive,
    Cancel,
    CheckCircle,
    Delete,
    Error,
    HourglassEmpty,
    NotInterested,
    PlayCircleFilled,
    Stop,
    Timer,
    TimerOff
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core";
import {
    ARCHIVED,
    COMPLETED,
    DELETE,
    ERROR,
    EXPIRED,
    INTERRUPTED,
    PROGRESS,
    SCHEDULED,
    SUSPENDED,
    STOPPING
} from "../../../utils/filters/scheduled-task-filter";
import {deleteTask} from "../../../actions/delete";
import {stopSyncSitemapTask} from "../../../actions/put";


const defaultHoverStyle = {
    cursor: 'pointer'
};
const useStyles = makeStyles({
    done: {
        color: 'green',
        margin: '1px',
        '&:hover': defaultHoverStyle,
    },
    error: {
        color: 'red',
        margin: '1px',
        '&:hover': defaultHoverStyle,
    },
    pianificato: {
        color: 'orange',
        margin: '1px',
        '&:hover': {
            cursor: 'pointer',
            color: 'orange'
        },
    },
    archiviato: {
        color: 'darkgray',
        margin: '1px',
        '&:hover': defaultHoverStyle,
    },
    annullato: {
        color: 'red',
        margin: '1px',
        '&:hover': defaultHoverStyle,
    },
    interrotto: {
        color: 'red',
        margin: '1px',
        '&:hover': defaultHoverStyle,
    },
    progress: {
        color: 'orange',
        margin: '1px',
        '&:hover': {
            cursor: 'pointer',
            color: 'red'
        },
    },
});

const StartStopButton = (props) => {
    let record;
    const contextRecord = useRecordContext(props);
    if (contextRecord === undefined) {
        record = props.record;
    } else {
        record = contextRecord;
    }
    const [uploadActionStatus, setUploadActionStatus] = React.useState(INACTIVE);
    const [scheduleStatus, setScheduleStatus] = React.useState(record.status);
    const [errorDetail, setErrorDetail] = React.useState('');
    const [hoverStatus, setHoverStatus] = React.useState(false);
    const refreshReport = useRefresh();
    const classes = useStyles();

    const updateStatusAction = (e) => {
        const callback = (updatedStatus, error) => {
            setScheduleStatus(updatedStatus)
            setUploadActionStatus(updatedStatus)
            setErrorDetail(error)
            refreshReport()
        }
        let request = {
            'id': record.id,
            'name': record.name,
            'status': updatedStatus,
            'startTime': record.startTime,
            'endTime': record.endTime,
            'resultData': record.resultData,
            'errorDescription': record.errorDescription,
            'jobId': record.jobId
        }
        if (updatedStatus === DELETE) {
            deleteTask(request, (e, error) => {
                    callback(updatedStatus, error)
                }
            )
        } else {
            props.stopTaskStatus((e, error) => {
                    callback(updatedStatus, error)
                }
            )
        }
    }

    let feedback = ''
    switch (uploadActionStatus) {
        case FAILED:
            feedback = <div className={'result-feedback failed-result'}>Impossibile effettuare
                l'operazione: {errorDetail}</div>
            break;
        case SUCCESS:
            feedback = <div className={'result-feedback success-result'}></div>
            break;
    }
    let updatedStatus = ''
    switch (record.status) {
        case PROGRESS:
            updatedStatus = INTERRUPTED;
            break;
        case SCHEDULED:
            updatedStatus = SUSPENDED;
            break;
        case ARCHIVED:
            updatedStatus = DELETE;
            break;
        default:
            updatedStatus = ARCHIVED;
    }
    const icons = []
    const archiveIcon = <Archive className={classes.archiviato}></Archive>;
    icons[ARCHIVED] = {
        "currenticon": archiveIcon,
        "nexticon": <Delete className={classes.annullato}></Delete>,
        'currenttitle': 'archivia',
        "nexttitle": DELETE
    }
    icons[INTERRUPTED] = {
        "currenticon": <Cancel className={classes.interrotto}></Cancel>,
        "nexticon": archiveIcon,
        'currenttitle': INTERRUPTED,
        "nexttitle": 'archivia'
    }
    icons[SUSPENDED] = {
        "currenticon": <NotInterested className={classes.annullato}></NotInterested>,
        "nexticon": archiveIcon,
        'currenttitle': SUSPENDED,
        "nexttitle": 'archivia'
    }
    icons[SCHEDULED] = {
        "currenticon": <Timer className={classes.pianificato}></Timer>,
        "nexticon": <TimerOff className={classes.annullato}></TimerOff>,
        'currenttitle': SCHEDULED,
        "nexttitle": 'archivia'
    }
    icons[COMPLETED] = {
        "currenticon": <CheckCircle className={classes.done}></CheckCircle>,
        "nexticon": archiveIcon,
        'currenttitle': COMPLETED,
        "nexttitle": 'archivia'
    }
    icons[EXPIRED] = {
        "currenticon": <HourglassEmpty className={classes.error}/>,
        "nexticon": archiveIcon,
        'currenttitle': EXPIRED,
        "nexttitle": 'archivia'

    }
    icons[ERROR] = {
        "currenticon": <Error className={classes.error}></Error>,
        "nexticon": archiveIcon,
        'currenttitle': ERROR,
        "nexttitle": 'archivia'
    }
    icons[PROGRESS] = {
        "currenticon": <PlayCircleFilled className={classes.progress}/>,
        "nexticon": <Stop className={classes.progress}></Stop>,
        'currenttitle': PROGRESS,
        "nexttitle": 'ferma'
    }
    icons[SCHEDULED] = {
        "currenticon": <Timer className={classes.pianificato}></Timer>,
        "nexticon": <TimerOff className={classes.annullato}></TimerOff>,
        'currenttitle': SCHEDULED,
        "nexttitle": 'sospendi'
    }
    icons[DELETE] = {
        "currenticon": '',
        "nexticon": '',
        'currenttitle': '',
        "nexttitle": ''
    }
    icons[STOPPING] = {
        "currenticon": <HourglassEmpty className={classes.interrotto}></HourglassEmpty>,
        "nexticon": archiveIcon,
        'currenttitle': STOPPING,
        "nexttitle": 'archivia'
    }
    if (record.status === null) {
        return <div></div>
    }
    return <div className={'update-button-toolbar-wrapper'}>
        <div className={'update-button-toolbar'}>
            <div className={'update-button-toolbar-button'}>
                <div title={hoverStatus ? icons[record.status].nexttitle : icons[record.status].currenttitle}>
                    <a onClick={updateStatusAction} onMouseOver={(e) => setHoverStatus(true)}
                       onMouseLeave={(e) => setHoverStatus(false)}>{hoverStatus ? icons[record.status].nexticon : icons[record.status].currenticon}</a>
                </div>
            </div>
            <div className={'update-button-toolbar-loading'}>
                <LoadingIcon actionStatus={uploadActionStatus}></LoadingIcon>
            </div>
        </div>
        <div className={'update-button-toolbar-feeback'}>{feedback}</div>
    </div>
}

StartStopButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired
};

export default StartStopButton;