import * as React from "react";
import PropTypes from "prop-types";
import {Button, makeStyles} from "@material-ui/core";
import {Check, Undo} from "@material-ui/icons";

const useStyles = makeStyles({
    action: {
        color: 'white',
        backgroundColor: '#3f51b5',
        '&:hover': {
            color: '#3f51b5',
            backgroundColor: '#ffffff',
            borderColor: '#3f51b5',
            boxShadow: '0 0 0 2px rgba(63,81,181,.5)',
        },
    },
    undoButton: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            color: '#ff0000',
            backgroundColor: '#ffffff',
            borderColor: '#ff0000',
            boxShadow: '0 0 0 2px rgba(255,0,0,.5)',
        },
    },
});

export const ActionButton = (props) => {
    const classes = useStyles();
    let className;
    switch (props.style){
        case secondaryStyle:
            className = classes.undoButton;
            break
        default:
            className = classes.action;
            break
    }
    let icon
    if(props.icon !== undefined ){
        icon = props.icon
    }else if(props.style === secondaryStyle){
        icon = <Undo></Undo>
    }else{
        icon = <Check></Check>
    }
    return <Button
        className={className}
        endIcon={icon}
        variant={props.variant}
        color={props.color}
        onClick={props.action}>{props.label}</Button>
}

export const primaryStyle = 'execute';
export const secondaryStyle = 'delete';
ActionButton.propTypes = {
    label: PropTypes.string.isRequired,
    endIcon: PropTypes.element,
    icon: PropTypes.element,
    variant: PropTypes.string,
    color: PropTypes.string,
    action: PropTypes.func.isRequired,
    style: PropTypes.oneOf([primaryStyle, secondaryStyle])
};