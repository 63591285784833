import React from 'react'
import {EXCEL_MIME, ZIP_MIME} from "../../../config/filetypes";
import {FAILED, LoadingIcon, SUCCESS} from "../loading/LoadingIcon";
import ImportExcelPanel from "../../resources/excel-products/ImportExcelPanel";

export default function UploadFeedback(props) {
    let uploadFeedback = <div className={"result-feedback success-result"}>{props.uploadedmessage}</div>;
    let importExcelDataPanel = '';
    if (props.filetype === EXCEL_MIME) {
        importExcelDataPanel =
            <div className={'import-excel-file-data-wrapper'}>
                <ImportExcelPanel title={'IMPORTA I DATI DEL FILE'} successEndpoint={props.successEndpoint} />
            </div>
    } else if (props.filetype === ZIP_MIME) {
        importExcelDataPanel =
            <div className={'import-excel-file-data-wrapper'}>
                <ImportExcelPanel title={'IMPORTA LE IMMAGINI'} successEndpoint={props.successEndpoint} />
            </div>
    }
    return <div>
        <LoadingIcon actionStatus={props.uploaded}></LoadingIcon>
        <div className={"upload-excel-upload-feedback " + (props.uploaded === SUCCESS ? '' : 'hide-btn')}>
            {uploadFeedback}
            {importExcelDataPanel}
        </div>
        <div className={"upload-excel-upload-feedback " + (props.uploaded === FAILED ? '' : 'hide-btn')}>
            <div className={"result-feedback failed-result"}>
                {props.uploadedmessage}
            </div>
        </div>
    </div>
}