import {FAILED, PROGRESS, SUCCESS} from "../utils/loading/LoadingIcon";
import {put} from "../utils/rest/RestClient";
import {apiVersion} from "../../config/exchange";

export const stopSyncProductsTask = (callback) => {
    return stopTask(callback, `${apiVersion}/job/sync-products/stop`);
}

export const stopSyncCustomersTask = (callback) => {
    return stopTask(callback, `${apiVersion}/job/sync-customers/stop`);
}

export const stopSyncSitemapTask = (callback) => {
    return stopTask(callback, `${apiVersion}/job/sync-sitemap/stop`);
}

function stopTask(callback, endpoint) {
    callback(PROGRESS, '')
    put(endpoint, '')
        .then(function () {
            callback(SUCCESS, '')
        })
        .catch(function (error) {
            let message = error.response.data.message;
            if (message === '') {
                message = error.response.data.error
            }
            callback(FAILED, message)
        })
}


// export const updateTaskStatus = (body, callback) => {
//     let endpoint = `${apiVersion}/job/sync-products/stop`;
//     if(body.status===''){
//         return null;
//     }
//     callback(PROGRESS, '')
//     put(endpoint, body)
//         .then(function () {
//             callback(SUCCESS, '')
//         })
//         .catch(function (error) {
//             let message = error.response.data.message;
//             if (message === '') {
//                 message = error.response.data.error
//             }
//             callback(FAILED, message)
//         })
// }